<template>
    <NuxtLayout
        class="error"
        name="default"
    >
        <BaseArticle class="component">
            <h1 v-if="error && error.statusCode === 404">{{ $t('error.title.404') }}</h1>
            <h1 v-else-if="error && error.title">{{ $t(error.title) }}</h1>
            <h1 v-else>{{ $t('error.title.other') }}</h1>

            <p v-if="error && error.message">{{ $t(error.message) }}</p>

            <p>
                <i18n-t keypath="error.body.text">
                    <template #home>
                        <NuxtLink :to="localePath({ name: 'index' })">{{ $t('error.body.home') }}</NuxtLink>
                    </template>
                    <template #zoek>
                        <NuxtLink :to="localePath({ name: 'zoek' })">{{ $t('error.body.zoek') }}</NuxtLink>
                    </template>
                </i18n-t>
            </p>
        </BaseArticle>
    </NuxtLayout>
</template>

<script setup>
defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const localePath = useLocalePath();
</script>
