import { createClient, defaultPlugins } from 'villus';
import { bearerToken } from '@digitalnatives/villus-plugins';

import Redirects from '~/graphql/queries/Redirects.graphql';

export const useRedirects = async() => {
    const redirectStore = useRedirectsStore();
    const { redirects } = storeToRefs(redirectStore);

    if (redirects.value === null) {
        redirects.value = await fetch();
    }

    return {
        redirects
    };
};

const fetch  = async() => {
    const { activeSite } = useMultisite();
    const { public: { apiEndpoint, apiToken} } = useRuntimeConfig();

    const client = createClient({
        url: apiEndpoint,
        use: [bearerToken(apiToken), ...defaultPlugins()]
    });

    const { data } = await client.executeQuery({
        query: Redirects,
        variables: {
            siteId: activeSite.value.toString()
        }
    });

    return data?.redirects ?? [];
};
