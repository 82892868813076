export default defineAppConfig({
    theme: 'theme--doen',
    title: '<%= title %> | Stichting DOEN',
    algolia: {
        index: 'DOEN_search',
        projectIndex: 'DoenProjects'
    },
    enabledFeatures: [
        'projects'
    ],
    GtmId: import.meta.env.VITE_GTM_DOEN
});
