import AOS from 'aos';
import 'aos/dist/aos.css';

export default defineNuxtPlugin({
    name: 'aos',
    parallel: true,
    hooks: {
        'page:finish'() {
            AOS.init({
                duration: 500,
                offset: 0
            });
        }
    }
});
