export default defineNuxtPlugin({
    name: 'multisite',
    parallel: true,
    async setup() {
        const { activeSite } = useMultisite();

        watchEffect(() => {
            switch (activeSite.value) {
            case 3: // VLF
                updateAppConfig({
                    theme: 'theme--bglf',
                    title: '<%= title %> | VriendenLoterij Fonds',
                    algolia: {
                        index: 'VriendenLoterij Fonds_search',
                        projectIndex: 'BglfProjects'
                    },
                    enabledFeatures: [
                        'projects', 'quickscan'
                    ],
                    GtmId: import.meta.env.VITE_GTM_VLF
                });
                break;
            case 4: // Participaties
                updateAppConfig({
                    theme: 'theme--participaties',
                    title: '<%= title %> | DOEN Participaties',
                    algolia: {
                        index: 'DOEN Participaties_search',
                        portfolioIndex: 'DoenParticipatiesPortfolio'
                    },
                    enabledFeatures: [
                        'portfolio'
                    ],
                    GtmId: import.meta.env.VITE_GTM_PARTICIPATIES
                });
                break;
            case 7: // Participaties EN
                updateAppConfig({
                    theme: 'theme--participaties',
                    title: '<%= title %> | DOEN Participaties',
                    algolia: {
                        index: 'DOEN Participaties EN_search',
                        portfolioIndex: 'DoenParticipatiesEnPortfolio'
                    },
                    enabledFeatures: [
                        'portfolio'
                    ],
                    GtmId: import.meta.env.VITE_GTM_PARTICIPATIES
                });
                break;
            case 5: // DOEN EN
                updateAppConfig({
                    theme: 'theme--doen',
                    title: '<%= title %> | Stichting DOEN',
                    algolia: {
                        index: 'DOEN EN_search',
                        projectIndex: 'DoenEnProjects'
                    },
                    enabledFeatures: [
                        'projects', 'quickscan'
                    ],
                    GtmId: import.meta.env.VITE_GTM_DOEN
                });
                break;
            case 2: // DOEN
            default:
                updateAppConfig({
                    theme: 'theme--doen',
                    title: '<%= title %> | Stichting DOEN',
                    algolia: {
                        index: 'DOEN_search',
                        projectIndex: 'DoenProjects'
                    },
                    enabledFeatures: [
                        'projects', 'quickscan'
                    ],
                    GtmId: import.meta.env.VITE_GTM_DOEN
                });
            }
        });
    }
});
