export const useMultisite = () => {
    const runtimeConfig = useRuntimeConfig();
    const url = useRequestURL();
    const nuxtApp = useNuxtApp();

    const activeSite = computed(() => runtimeConfig?.public?.sites?.[url?.hostname]?.[nuxtApp.$i18n.locale.value] ?? runtimeConfig.public.defaults.siteId);
    const sitesInGroup = computed(() => {
        return Object.values(runtimeConfig?.public?.sites?.[url?.hostname] ?? { key: runtimeConfig.public.defaults.siteId });
    });

    const isDoen = computed(() => url.hostname === 'www.doen.nl' || url.hostname === 'localhost' || url.hostname === 'doen.natives.dev');
    const isBglf = computed(() => url.hostname === 'vriendenloterijfonds.doen.nl' || url.hostname === 'vriendenloterijfonds.doen.test' || url.hostname === 'vriendenloterijfonds.doen.natives.dev');
    const isParticipaties = computed(() => url.hostname === 'participaties.doen.nl' || url.hostname === 'participaties.doen.test' || url.hostname === 'participaties.doen.natives.dev');

    return {
        activeSite,
        isBglf,
        isDoen,
        isParticipaties,
        sitesInGroup,
    };
};
