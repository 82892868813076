import { Icon } from 'leaflet';

const VueLeaflet = {
    install() {
        setIconDefault();
    }
};

function setIconDefault() {
    delete Icon.Default.prototype._getIconUrl;

    Icon.prototype._createImg = (src, el) => {
        const img = document.createElement('img');

        el = el || document.createElement('figure');

        img.src = src;

        el.innerHTML = '';
        el.appendChild(img);

        return el;
    };

    Icon.Default.mergeOptions({
        iconRetinaUrl: '/img/markers/default.svg',
        iconUrl: '/img/markers/default.svg',
        shadowUrl: '/img/markers/shadow.svg',
        iconSize: [38, 46],
        shadowSize: [56, 65]
    });
}

export default defineNuxtPlugin({
    name: 'vue-leaflet-dn',
    parallel: true,
    setup(nuxtApp) {
        nuxtApp.vueApp.use(VueLeaflet);
    }
});
